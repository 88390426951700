import "../styles/Home.css";
export default function Home() {
  return (
    <div id="home" className="relative isolate overflow-hidden bg-white">
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl py-4  sm:px-6 sm:py-24 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16  sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-10">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="homeName text-4xl font-bold  tracking-tight text-white sm:text-3xl">
              Hi <p className="movingHand">👋🏻</p> , I&apos;m
            </h2>
            <br />
            <h2 className="homeName animate-typing overflow-hidden whitespace-nowrap pr-5 text-5xl text-white font-bold">
              Rishabh Bandooni.
            </h2>

            <p className="mt-6 text-lg leading-8 text-gray-300">
              I am a self learned skilled frontend web developer who has a knack
              for all things tech.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
                href="#about"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Get started
              </a>
            </div>
          </div>
          <div className="homeDiv mx-auto max-w-lg center relative mt-16 h-80 lg:mt-4 ">
            <img
              className="homeImage absolute left-0 top-0  w-[20rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10 object-center"
              src="https://res.cloudinary.com/purnesh/image/upload/v1689850055/rishabh_xs4xx4.webp"
              alt="Personal"
              width={1824}
              height={1080}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
