import { CheckIcon } from "@heroicons/react/20/solid";
const socityFeatures = [
  "Added a new country section in the existing so.city website.The functionality: the user can choose their country, right now between India and France. Created the page for France in which all the data changes from English to French.",
  "Made a few Static and Dynamic Pages on the main website for clients and events like Hyundai-Alcazar, Careers Page, COVID Page, Horn OK Please, Boho Bazaar.",
  "Planned,developed,tested,deployed,and maintained web applications.",
  "More Projects Worked on At soCity Are below",
];
const rtrdashboardFeatures = [
  // "Planned,developed,tested,deployed,and maintained web applications.",
  "The SO app follows a question/answer format basis which the users who engage are allotted points.",
  "Created a new responsive dashboard for the company that retrieves the data of the users who engaged on our application from the back-end.",
  "Created the dashboard using React-js, Redux, Firebase, and Material-UI.",
  "Features Included: Login Functionality, Search User, and Filter Functionalities such as Filter by score, Banned or unbanned user. Pagination and infinite-scroller for smooth use and moving from one page to another.",
];
const solinksFeatures = [
  // "Planned,developed,tested,deployed,and maintained web applications.",
  "Made a New Instagram LinkinBio Page clone For the company in which multiple links could be provided for their featured and sponsored articles, current events going on, and current jobs hiring all under one link.",
  "All of the content on this page is uploaded from a separate dashboard we created to keep it dynamic .",
  "Dashboard functionality : To Add New Events ,Articles,Jobs And All the Google Form Links, everything, All in One place.",
  "The dashboard was created using React-js, Material-UI, Firebase.",
];
const multiplepagesFeatures = [
  // "Planned,developed,tested,deployed,and maintained web applications.",
  "Made a few Static and Dynamic Pages on the main website for clients and events like Hyundai-Alcazar, Careers Page, COVID Page, Horn OK Please, Boho Bazaar.",
  "Made a static micro-site for one event property, that is, the Wonder carnival .",
];
const reactcartFeatures = [
  "The E-commerce Website is a comprehensive online shopping platform that I developed using a combination of cutting-edge technologies including React-js, Next-js, Firebase, Redux, FakeStoreAPI, Stripe API, and Tailwind CSS",
  " The primary objective of this project was to create a robust and feature-rich e-commerce solution that seamlessly integrates with various APIs and provides a smooth shopping experience for users.",
  "Key Features: - User Authentication ,Product Catalog , Shopping Cart and Checkout , Order Management , Responsive Design",
  "This project showcases my ability to build scalable, feature-rich web applications with a focus on user experience and functionality.",
];
const imageGalleryFeatures = [
  "The React Image Gallery is a dynamic and user-friendly web application.",
  "It allows users to search and retrieve images by their respective names.",
  "The core functionality of the project relies on an integrated API, which seamlessly fetches and populates the gallery with relevant images based on the user's search query.",
  "Through the development of the React Image Gallery, I honed my skills in React.js, API integration, Material-ui and Axios.",
];

export default function Experience() {
  return (
    <div
      id="experience"
      className=" relative isolate overflow-hidden bg-white py-24 sm:py-32"
    >
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Work Experience
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Tenacious and Efficient React Developer with 2 Years of Astute
            experience in Frontend Web Development.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              Frontend Web Developer At SoCity
            </h3>
            <br />
            <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
              09/ 2021 - Present
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              SO is a leading city & entertainment guide filled with handpicked
              recommendations of what to do in your city, explore hidden gems
              and be the first to get the latest buzz!
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                Achievements/Tasks
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-left text-gray-600 sm:grid-cols-2 sm:gap-6">
              {socityFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-2">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 flex items-center justify-center">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-4">
              <div className="mx-auto max-w-lg px-2 max-w-[700px] max-h-[700px] overflow-hidden">
                <img
                  className="hover:scale-125 transition duration-500 cursor-pointer rounded-2xl mt-6 flex items-baseline justify-center gap-x-2 "
                  src="https://res.cloudinary.com/purnesh/image/upload/v1689850150/socity_home_py3bxy.webp"
                  alt="reactCart"
                />
                <a
                  href="https://so.city/"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Live Preview
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
              RTR Dashboard
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Created a new responsive dashboard for the company that retrieves
              the data of the users who engaged on our application from the
              back-end.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                Achievements/Tasks
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-left text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {rtrdashboardFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 flex items-center justify-center">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-4">
              <div className="mx-auto max-w-lg px-2 max-w-[700px] max-h-[700px] overflow-hidden">
                <img
                  className="hover:scale-125 transition duration-500 cursor-pointer  rounded-2xl mt-6 flex items-baseline justify-center gap-x-2"
                  src="https://res.cloudinary.com/purnesh/image/upload/v1689850113/rtr_user_zudlus.webp"
                  alt="rtrdashboard"
                />
                <a
                  href="google.com"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Live Preview
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 3rd */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
              SoLinks Dashboard
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Made a New Instagram LinkinBio Page Clone For the company in which
              multiple links could be provided for their featured and sponsored
              articles, current events going on, and current jobs hiring all
              under one link.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                Achievements/Tasks
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-left text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {solinksFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 flex items-center justify-center">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-4">
              <div className="mx-auto max-w-lg px-2 max-w-[700px] max-h-[700px] overflow-hidden">
                <img
                  className="hover:scale-125 transition duration-500 cursor-pointer  rounded-2xl mt-6 flex items-baseline justify-center gap-x-2"
                  src="https://res.cloudinary.com/purnesh/image/upload/v1689850057/so_links_suzcyi.webp"
                  alt="reactCart"
                />
                <a
                  href="https://so.city/links"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Live Preview For Website
                </a>
                <a
                  href="http://157.245.106.44/jobs"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Live Preview For Dashboard
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 4th */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
              Multiple Dynamic and Static Web Pages for Socity
            </h3>

            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                Achievements/Tasks
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-left text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {multiplepagesFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 flex items-center justify-center">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-4">
              <div className="mx-auto max-w-lg px-2 max-w-[700px] max-h-[700px] overflow-hidden">
                <img
                  className="hover:scale-125 transition duration-500 cursor-pointer  rounded-2xl mt-6 flex items-baseline justify-center gap-x-2"
                  src="https://res.cloudinary.com/purnesh/image/upload/v1689850126/socity_paris_tiai7f.webp"
                  alt="reactCart"
                />
                <a
                  href="https://so.city/delhi"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Live Preview
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 5th */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
              reactCart - An E-commerce platform
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Discover, shop, and experience the ultimate online marketplace
              with our e-commerce platform built on ReactJS, offering a seamless
              and personalized shopping journey for every user.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                Achievements/Tasks
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-left text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {reactcartFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 flex items-center justify-center">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-4">
              <div className="mx-auto max-w-lg px-2 max-w-[700px] max-h-[700px] overflow-hidden">
                <img
                  className="hover:scale-125 transition duration-500 cursor-pointer  rounded-2xl mt-6 flex items-baseline justify-center gap-x-2"
                  src="https://res.cloudinary.com/purnesh/image/upload/v1689850106/react_cart_iewvpm.webp"
                  alt="reactCart"
                />
                <a
                  href="https://react-cart-gray.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Live Preview For Website
                </a>
                <a
                  href="https://github.com/rishabhbandooni/reactCart"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Link For the Code
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 5th */}
        {/* 6th */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
              React Image Gallery
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              React Image Gallery is a dynamic project that fetches and displays
              images from an API based on user search queries.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                Achievements/Tasks
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-left text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {imageGalleryFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 flex items-center justify-center">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-4">
              <div className="mx-auto max-w-lg px-2 max-w-[700px] max-h-[700px] overflow-hidden">
                <img
                  className="hover:scale-125 transition duration-500 cursor-pointer  rounded-2xl mt-6 flex items-baseline justify-center gap-x-2"
                  src="https://res.cloudinary.com/purnesh/image/upload/v1689850129/image_gallery_qvxhmp.webp"
                  alt="reactImageGallery"
                />
                <a
                  href="https://react-image-gallery-delta.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Live Preview For Website
                </a>
                <a
                  href="https://github.com/rishabhbandooni/react-image-gallery"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Link For the Code
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 6th */}
      </div>
    </div>
  );
}
