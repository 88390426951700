export default function Skills() {
  return (
    <div
      id="skills"
      className="relative isolate overflow-hidden bg-white py-24 sm:py-32"
    >
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
        <h2 className="text-left text-4xl  font-bold leading-8 text-gray-900">
          <u>My Skills</u>
        </h2>

        <div className="mx-auto mt-20 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5 ">
          <img
            className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716628/html5_k6z4qq.png"
            alt="HTML5"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716628/css_iy3v5j.png"
            alt="CSS3"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716629/js_bhcmsq.png"
            alt="Javascript"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716629/react_cwh6p5.png"
            alt="React"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716629/redux_gki2jl.png"
            alt="Redux"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716629/vue_mq77mx.png"
            alt="Vue"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716628/firebase_dwg7sk.png"
            alt="Firebase"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716628/git_ioep7r.png"
            alt="Git"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716629/mui_reay3s.png"
            alt="MUI"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-16 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716629/tailwind_b9tlhw.png"
            alt="Tailwind"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-20 w-full object-contain lg:col-span-1 "
            src="https://res.cloudinary.com/purnesh/image/upload/v1688716628/bootstrap_ujclkg.png"
            alt="Bootstrap"
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
  );
}
