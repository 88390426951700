import "../styles/About.css";
const links = [{ name: "Check Out My Skills", href: "#skills" }];

export default function About() {
  return (
    <div
      id="about"
      className="relative isolate overflow-hidden bg-white py-24 sm:py-32"
    >
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-30"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className=" flex mx-auto max-w-7xl px-6 lg:px-8">
        <div className=" mx-auto max-w-2xl lg:mx-0 ">
          <h2 className="text-4xl text-left font-bold tracking-tight text-black sm:text-6xl">
            About Me
          </h2>
          <p className="mt-6 text-left leading-8 text-black-300">
            I'm a front-end web developer with a background in computer systems
            and network infrastructure. My 2 years of IT experience has given me
            a strong foundation for web development and building complex
            solutions. Recently, I did my post-graduation from the Vivekanand
            College of Professional Studies. I am passionate about coding and
            solving problems through code, and I am excited to work alongside
            other amazing programmers and learn so much more!
          </p>

          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black-200 sm:grid-cols-2 md:flex lg:gap-x-10">
              {links.map((link) => (
                <a key={link.name} href={link.href}>
                  {link.name} <span aria-hidden="true">&rarr;</span>
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="aboutGif mx-auto max-w-lg center relative mt-16 h-80 lg:mt-4 right-10">
          <img
            className="aboutImage absolute   w-[20rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
            src="https://res.cloudinary.com/purnesh/image/upload/v1689850106/Aboutme_zplhr0.webp"
            alt="App screenshot"
            width={1824}
            height={1080}
          />
        </div>
      </div>
    </div>
  );
}
